import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Carousel} from 'react-bootstrap'
import {Button, Modal, Spin, Tooltip} from 'antd'
import {CloseOutlined, CloudDownloadOutlined, EditOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {IMAGE_MODAL_PREVIEW_TYPE} from '../constant'
import UploadFileService from '../services/uploadFIleService'
import {getFileBase64} from "../utils/file";
import pMap from "p-map";
import FileUploaderService from "../services/fileUploaderService";
import {__} from '../../../../../../utils/translationUtils'

import "@syncfusion/ej2-react-image-editor/styles/material.css"
import ImageEditor, {blobToDataURL} from "../../../../../ImageEditor";

const ImageCarouselModal = ({
                              previewVisible,
                              selectedImageName,
                              onCloseModal,
                              fileList,
                              downloadFile,
                              handleUploadFile,
                              directory,
                              type,
                              fileCollection,
                              authToken
                            }) => {
  const [fetchData, setFetchData] = useState(true)
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState(null)
  const [editImage, setEditImage] = useState(null)
  const editorRef = useRef();

  /* Since this modal is continually closed and open with different props I need a method to reset the state, so
  * everytime the modal is open I will have the same behaviour
  * */
  const resetState = () => {
    setFetchData(true)
    setLoading(false)
    setImages([])
    setCurrentImage(null)
  }

  /* In this component I have to retrieve the entire image without the thumbnail otherwise it will be impossible to download
  * an image without the scale
  *  */
  const fetchPreviews = useCallback(async (imageList = []) => {
    const filePreviews = {}
    await pMap(imageList, async image => {
      let fileBlob = await FileUploaderService.getUrlFile(image.mediumUrl, authToken)
      fileBlob = fileBlob.slice(0, fileBlob.size, 'image/png')
      filePreviews[image.name] = await getFileBase64(fileBlob)
    }, {concurrency: 5})

    return filePreviews
  }, [authToken, directory, fileCollection, type])

  useEffect(() => {
    const fetch = async (imageList) => {
      setLoading(true)

      const imagePreviews = await fetchPreviews(imageList)
      const imagesNew = (imageList || []).map((image, index) => ({
        ...image,
        preview: imagePreviews[image.name]
      }))

      setImages(imagesNew)
      setCurrentImage(imagesNew.find((i) => i.name === selectedImageName))
      setLoading(false)
    }

    const imageList = UploadFileService.filterImage(fileList)

    if (imageList.length < 1 || !previewVisible) {
      setLoading(false)
      return
    }

    if (fetchData && selectedImageName) {
      fetch(imageList).then(() => setFetchData(false))
    }
  }, [fileList, selectedImageName, previewVisible, fetchPreviews, fetchData])

  const handleDownload = () => {
    currentImage?.name && downloadFile(currentImage)
  }

  const handleCarouselSelect = (selectedIndex) => {
    if (images[selectedIndex] != null) {
      setCurrentImage(images[selectedIndex])
    }
  }

  const handleCloseModal = () => {
    editorRef?.current?.destroy?.()
    resetState()
    onCloseModal?.()
  }

  const handleEdit = async () => {
    setLoading(true)
    FileUploaderService.getUrlFile(currentImage.url, authToken).then((fileBlob) => {
      blobToDataURL(fileBlob).then((dataURL) => {
        setEditImage(dataURL)
        setLoading(false)
      })
    })
  }

  return (
    <Modal
      visible={previewVisible}
      title={(
        <div
          className="godoo-modal-header"
          style={{
            width: '100%',
            cursor: 'move'
          }}
        >
          <div className="godoo-modal-title">{!editImage ? __('Image Preview') : `${__('Edit Image')} ${currentImage?.name}` }</div>
          <div className="godoo-modal-button-wrapper">
            {
              !editImage && !!handleUploadFile && (
                <Tooltip
                  title={__('Edit')}
                  mouseEnterDelay="0"
                  mouseLeaveDelay="0"
                  placement="bottom"
                  overlayStyle={{marginTop: '-35px'}}
                >
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        className="godoo-modal-button"
                        width="20px"
                        height="20px"
                        onClick={handleEdit}
                      />
                    }
                  />
                </Tooltip>
              )
            }
            {
              !editImage && (
                <Tooltip
                  title={__('Download')}
                  mouseEnterDelay="0"
                  mouseLeaveDelay="0"
                  autoAdjustOverflow="false"
                  placement="bottom"
                  overlayStyle={{marginTop: '-35px'}}
                >
                  <Button
                    type="text"
                    icon={(
                      <CloudDownloadOutlined
                        className="godoo-modal-button"
                        width="20px"
                        height="20px"
                        onClick={handleDownload}
                      />
                    )}
                  />
                </Tooltip>
              )
            }
            <div className="godoo-modal-button-separator"/>
            <Tooltip
              title={__('Close')}
              mouseEnterDelay="0"
              mouseLeaveDelay="0"
              placement="bottom"
              overlayStyle={{marginTop: '-35px'}}
            >
              <Button
                type="text"
                icon={
                  <CloseOutlined className="godoo-modal-button" width="20px" height="20px" onClick={handleCloseModal}/>
                }
              />
            </Tooltip>
          </div>
        </div>
      )}
      footer={null}
      zIndex={9000}
      closable={false}
      onCancel={handleCloseModal}
      width={"fit-content"}
      style={{borderRadius: '8px'}}
      destroyOnClose
    >
      <Spin spinning={loading}>
        {
          !editImage && (
            <>
              {images.length === 1
                && (
                  <img
                    alt="File Preview not available"
                    style={{width: '100%'}}
                    src={images && images[0] ? (images[0].preview) : undefined}
                  />
                )}
              {images.length > 1
                && (
                  <Carousel
                    activeIndex={currentImage?.name != null ? (images || []).findIndex((i) => i.name === currentImage.name) : 0}
                    onSelect={handleCarouselSelect}
                    style={{height: '50vh'}}
                  >
                    {images.map(({name, preview}) => (
                      <Carousel.Item
                        key={`key-${name}`}
                        style={{height: '100%'}}
                      >
                        <img
                          alt={`Image Preview for ${name} not available`}
                          style={{
                            width: '100%',
                            height: '100%',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                          }}
                          src={preview}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
            </>
          )
        }
        {
          !!editImage &&
          !!handleUploadFile &&
          <ImageEditor
            fileDefinition={currentImage}
            imageDataURL={editImage}
            ref={editorRef}
            saveFile={(file) => {
              setEditImage(null)
              setLoading(true)
              handleUploadFile({ file }, true).then((image) => {
                setImages(images.map((img) => ({
                  ...img,
                  ...(image.uid === img.uid ? image : {})
                })))
                setLoading(false)
              })
            }}
            close={() => {
              setEditImage(null)
            }}
            options={{ open: false }}
          />
        }
      </Spin>
    </Modal>
  )
}

/*
* Directory is required only
* if the type belongs to a Directory and not from a file Collection
* In the oppsite way fileCollection
* */
ImageCarouselModal.propTypes = {
  previewVisible: PropTypes.bool.isRequired,
  selectedImageName: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  type: PropTypes.oneOf([IMAGE_MODAL_PREVIEW_TYPE.FROM_DIRECTORY, IMAGE_MODAL_PREVIEW_TYPE.FROM_FILE_COLLECTION]).isRequired,
  directory(props, propName, componentName) {
    if ((props.type === IMAGE_MODAL_PREVIEW_TYPE.FROM_DIRECTORY && (props[propName] == undefined || typeof (props[propName]) !== 'object' || typeof (props[propName]).jsonPath !== 'string'))) {
      return new Error('Please provide a directory for this component!')
    }
  },
  fileList: PropTypes.array.isRequired,
  fileCollection(props, propName, componentName) {
    if ((props.type === IMAGE_MODAL_PREVIEW_TYPE.FROM_FILE_COLLECTION && (props[propName] == undefined || typeof (props[propName]) !== 'object' || !Array.isArray(props[propName]) || !props[propName].every((f) => typeof f.jsonPath === 'string')))) {
      return new Error('Please provide a fileCollection for this component!')
    }
  }
}

export default connect(
  (state) => ({authToken: state.user.authToken}),
  {}
)(ImageCarouselModal)
