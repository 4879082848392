import {Modal} from "components";
import React, {useEffect, useState, MutableRefObject, useCallback, useRef} from "react";
import {Button, Spin, Tooltip} from "antd";
import pMap from "p-map";
import FileUploaderService from "../services/fileUploaderService";
import {getFileBase64} from "../utils/file";
import {Carousel, SelectCallback} from "react-bootstrap";
import {__} from "../../../../../../utils/translationUtils";
import {CloseOutlined, CloudDownloadOutlined, EditOutlined} from "@ant-design/icons";
import ImageEditor, {blobToDataURL} from "../../../../../ImageEditor";
import {ImageEditorComponent} from "@syncfusion/ej2-react-image-editor";

type FieldProps = {
  fieldId: string
  fieldName: string
  fileList: ImageFileDefinition[]
  type: string
  readOnly: boolean
  handleUploadFile: ({ file }: { file: File }, replace: boolean) => any
  downloadFile: (imageFile: ImageFileDefinition) => void
}

type CommonImageCarouselModalProps = {
  selectedImageId: string
  carouselRef: MutableRefObject<Record<string, FieldProps>>
  onClose: () => void
  authToken: string
}
const CommonImageCarouselModal = ({ selectedImageId, carouselRef, onClose, authToken }: CommonImageCarouselModalProps) => {

  const [loading, setLoading] = useState(true)
  const [currentImgId, setCurrentImgId] = useState(selectedImageId)
  const [currentImage, setCurrentImage] = useState<ImageFileDefinition | undefined>(undefined)
  const [images, setImages] = useState<ImageFileDefinition[] | undefined>(undefined)
  const [currentFieldProps, setCurrentFieldProps] = useState<FieldProps | undefined>(undefined)

  const [editImage, setEditImage] = useState<string | undefined>(undefined)
  const editorRef = useRef<ImageEditorComponent>(null);

  const fetchPreviews = useCallback(async (imageList: ImageFileDefinition[] = []) => {
    const filePreviews: Record<string, string> = {}
    await pMap(imageList, async image => {
      let fileBlob = await FileUploaderService.getUrlFile(image.mediumUrl, authToken)
      fileBlob = fileBlob.slice(0, fileBlob.size, 'image/png')
      filePreviews[image.name] = await getFileBase64(fileBlob)
    }, {concurrency: 5})

    return filePreviews
  }, [authToken])

  const fetch = useCallback(async (imageList: ImageFileDefinition[]) => {
    const imagePreviews = await fetchPreviews(imageList)
    const imagesNew = (imageList || []).map((image, index) => ({
      ...image,
      preview: imagePreviews[image.name]
    }))

    setImages(imagesNew)
  }, [fetchPreviews])

  useEffect(() => {
    if (carouselRef.current) {
      setLoading(true)

      const imagesList = Object.values(carouselRef.current).map(({ fileList }) => fileList).flat(1)
      if (!imagesList.length) {
        setLoading(false)
        return
      }

      fetch(imagesList)
        .then(() => setLoading(false))
    }
  }, [])

  useEffect(() => {
    if (currentImgId && images) {
      const img = images.find(({ uid }) => currentImgId.includes(uid))
      if(img) {
        setCurrentImage(img)
        const fieldName = currentImgId.replace(img.uid, '')
        setCurrentFieldProps(carouselRef.current[fieldName])
      }
    }
  }, [currentImgId, images]);

  const handleSelectImg: SelectCallback = (index) => {
    const img = images?.[index]
    if (img) {
      setCurrentImgId(img.imageId)
    }
  }

  const handleDownload = () => {
    currentImage?.name && currentFieldProps?.downloadFile(currentImage)
  }

  const handleEdit = async () => {
    if(!currentImage) return

    setLoading(true)
    FileUploaderService.getUrlFile(currentImage.url, authToken).then((fileBlob) => {
      blobToDataURL(fileBlob).then((dataURL) => {
        setEditImage(dataURL)
        setLoading(false)
      })
    })
  }

  const handleCloseModal = () => {
    editorRef?.current?.destroy?.()
    onClose?.()
  }

  return (
    <Modal
      visible={!!currentImgId}
      title={(
        <div
          className="godoo-modal-header"
          style={{
            width: '100%',
            cursor: 'move',
            display: !currentFieldProps ? 'none' : "flex"
          }}
        >
          <div className="godoo-modal-title">
            {`${!!editImage ? __('Edit Image:') : ''} ${currentFieldProps?.fieldName}/${currentImage?.name}`}
          </div>
          <div className="godoo-modal-button-wrapper">
            {
              !currentFieldProps?.readOnly &&
              !editImage &&
              !!currentFieldProps?.handleUploadFile && (
                <Tooltip
                  title={__('Edit')}
                  mouseEnterDelay={0}
                  mouseLeaveDelay={0}
                  placement="bottom"
                  overlayStyle={{marginTop: '-35px'}}
                >
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        className="godoo-modal-button"
                        width="20px"
                        height="20px"
                        onClick={handleEdit}
                      />
                    }
                  />
                </Tooltip>
              )
            }
            {
              !editImage && (
                <Tooltip
                  title={__('Download')}
                  mouseEnterDelay={0}
                  mouseLeaveDelay={0}
                  autoAdjustOverflow={false}
                  placement="bottom"
                  overlayStyle={{marginTop: '-35px'}}
                >
                  <Button
                    type="text"
                    icon={(
                      <CloudDownloadOutlined
                        className="godoo-modal-button"
                        width="20px"
                        height="20px"
                        onClick={handleDownload}
                      />
                    )}
                  />
                </Tooltip>
              )
            }
            <div className="godoo-modal-button-separator"/>
            <Tooltip
              title={__('Close')}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              placement="bottom"
              overlayStyle={{marginTop: '-35px'}}
            >
              <Button
                type="text"
                icon={
                  <CloseOutlined className="godoo-modal-button" width="20px" height="20px" onClick={handleCloseModal}/>
                }
              />
            </Tooltip>
          </div>
        </div>
      )}
      footer={false}
      width={'fit-content'}
      destroyOnClose
      style={{
        minWidth: '300px',
        minHeight: '300px'
      }}
      closable={false}
      onCancel={handleCloseModal}
    >
      <Spin spinning={loading}>
        {
          !editImage && images && (
            <>
              {
                images.length === 1
                && (
                  <img
                    alt="File Preview not available"
                    style={{width: '100%'}}
                    src={images && images[0] ? (images[0].preview) : undefined}
                  />
                )
              }
              {
                images.length > 1
                && (
                  <Carousel
                    defaultActiveIndex={(images || []).findIndex((i) => i.imageId === currentImgId)}
                    onSelect={handleSelectImg}
                    style={{height: '50vh'}}
                    slide={false}
                  >
                    {
                      images.map(({name, preview}) => (
                        <Carousel.Item
                          key={`key-${name}`}
                          style={{height: '100%'}}
                        >
                          <img
                            alt={`Image Preview for ${name} not available`}
                            style={{
                              width: '100%',
                              height: '100%',
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                            src={preview}
                          />
                        </Carousel.Item>
                      ))
                    }
                  </Carousel>
                )
              }
            </>
          )
        }
        {
          !!currentImage &&
          !!editImage &&
          !!currentFieldProps?.handleUploadFile &&
          (
            <ImageEditor
              fileDefinition={currentImage}
              imageDataURL={editImage}
              ref={editorRef}
              saveFile={(file) => {
                setEditImage(undefined)
                setLoading(true)
                if (!images) return
                currentFieldProps?.handleUploadFile({ file }, true).then((image: ImageFileDefinition) => {
                  if (!images) return
                  setImages(images.map((img) => ({
                    ...img,
                    ...(image.uid === img.uid ? image : {})
                  })))
                  setLoading(false)
                })
              }}
              close={() => {
                setEditImage(undefined)
              }}
              options={{ open: false }}
            />
          )
        }
      </Spin>
    </Modal>
  )
}

export default CommonImageCarouselModal
