import React, {useCallback, useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Button, Empty, message, Upload} from "antd"
import {PlusCircleTwoTone} from "@ant-design/icons"
import downloadjs from "downloadjs";
import PropTypes from 'prop-types'

import FileUploaderService from '../services/fileUploaderService'
import {ILLEGAL_ARGUMENT_EXCEPTION, IMAGE_MODAL_PREVIEW_TYPE, UPLOAD_FILE_TYPE} from "../constant"
import UploadFileService from "../services/uploadFIleService"
import ImageCarouselModal from "./ImageCarouselModal"
import {fetchFileList, uploadFile} from "../utils/IO"
import {getExtensionFromFileName, getFileResourceInfo} from "../utils/misc";
import {convertSvgToPNG, getFileBase64, getImageThumbnail, resizeImage} from "../utils/file";
import {isImage} from "../../../../../../utils/appHelper"
import {uploadFullFile} from "../../../../../../providers/ApiProvider";

import '../../../../../../providers/StyleProvider/styles/fileUploader.scss'
import pMap from "p-map";
import {INFO, ERROR} from "components/dist/Utils/LoggerUtils";
import {__} from "../../../../../../utils/translationUtils";

const {Dragger} = Upload

/* I Added the version too, even if for now it's pretty useless but in the future*/
const UploadFileComponent = (props) => {
  const {
    authToken,
    directory,
    field,
    isReadOnly,
    onUploadFile,
    onRemoveFile,
    onUploadError,
    version,
    fileDefinition,
    context
  } = props

  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [selectedImageName, setSelectedImageName] = useState(null)
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialised, setInitialised] = useState(false)

  const accept = useMemo(() => FileUploaderService.retrieveMimeTypes(field), [field])

  const updateFile = useCallback((file, replace = false) => {
    if (!file) return

    setFileList((prevFiles) => {
      const i = prevFiles.findIndex(f => f.name === file.name)

      if (i > -1) {
        const newFileList = [...prevFiles]
        newFileList[i] = replace ? file : {...prevFiles[i], ...file}
        return newFileList
      } else {
        return [...prevFiles, file]
      }
    })
  }, [])

  const getFileListFromServer = useCallback(
    (filesToFetch, imageOptions) => fetchFileList(filesToFetch, imageOptions, authToken, updateFile),
    [authToken])

  const init = useCallback(async () => {
    setLoading(true)
    let files
    if (version === UPLOAD_FILE_TYPE.FILE_DEFINTION && fileDefinition != null) {
      files = fileDefinition.files
    } else {
      files = directory.files
    }

    const initFiles = (files || []).map((file, index) => {
      const resInfo = getFileResourceInfo(file, directory)

      return {
        uid: `-${file.name}`,
        name: file.name,
        type: file.type,
        ...(isImage(file.type) ? {status: 'uploading'} : {status: 'done', ...resInfo})
      }
    })

    setFileList(initFiles)

    pMap(initFiles, async file => {
      const resInfo = getFileResourceInfo(file, directory)

      getImageThumbnail(
        {...file, ...resInfo},
        authToken,
        (thumbnail) => {
          const changes = {
            ...file,
            status: 'done',
            ...resInfo,
            thumbUrl: thumbnail,
          }
          updateFile(changes)
        })

    }, {concurrency: 10}).then(() => INFO('Thumbnail files loaded'))

    setLoading(false)
  }, [])

  useEffect(() => {
    if (!initialised) {
      init()
      setInitialised(true)
    }
  }, [init, initialised])

  function handleCloseModal() {
    setPreviewModalVisible(false)
  }

  function handleOpenModal() {
    setPreviewModalVisible(true)
  }

  function renderIcon(file) {
    // HEIC files are not supported by ANT Preview. For now, we show a file icon instead.
    if (file?.name?.toLowerCase().endsWith('.heic')) {
      return false
    }
    const extension = getExtensionFromFileName(file.name)
    return isImage(extension)
  }

  function handlePreview(file) {
    if (file == null) {
      ERROR(ILLEGAL_ARGUMENT_EXCEPTION)
      return
    }

    if (isImage(file.type)) {
      const { setCarouselImageOpen } = field
      const imgName = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)

      if (setCarouselImageOpen) {
        setCarouselImageOpen?.(`${field.id}-${imgName}`)
      } else {
        setSelectedImageName(imgName)
        handleOpenModal()
      }
    } else {
      handleDownloadFile(file)
    }
  }

  async function handleSuccessRequest(file) {
    if (typeof onUploadFile === 'function') {
      await onUploadFile(directory.jsonPath, file)
    }

    const resInfo = getFileResourceInfo(file, directory)

    if (isImage(file.type)) {
      let thumbFile = (file.type === 'image/svg+xml')
        ? await convertSvgToPNG(file, 200, 200, 0.8)
        : file

      const newFile = await resizeImage(thumbFile, 200, 200, 0.8).then(async (small) => {
        uploadFullFile(resInfo.smallUrl, small)
        return addUploadedFile(file, resInfo, await getFileBase64(small))
      })
      return resizeImage(thumbFile, 720, 720, 0.8).then(async (medium) => {
        uploadFullFile(resInfo.mediumUrl, medium)
        return {
          ...newFile,
          preview: await getFileBase64(medium)
        }
      })
    } else {
      addUploadedFile(file, resInfo)
    }
  }

  const addUploadedFile = (file, resInfo, thumbBase64) => {
    const ext = getExtensionFromFileName(file.name)

    const fileAdded = {
      uid: `-${file.name}`,
      name: file.name,
      status: 'done',
      type: ext,
      ...resInfo,
      ...(thumbBase64 && {thumbUrl: thumbBase64})
    }

    updateFile(fileAdded)
    message.success('File Uploaded successfully')

    return fileAdded
  }

  async function handleErrorRequest(file) {
    if (typeof onUploadError === 'function') {
      await onUploadError(file)
    }

    message.error('The file cannot be updated. Please contact Administrator')
  }

  async function handleUploadFile({ file }, replace = false) {
    if (file == null) {
      ERROR(ILLEGAL_ARGUMENT_EXCEPTION)
      return
    }

    setLoading(true)

    const fileBlob = file.slice(0, file.size, file.type)

    let fileName

    if (replace)
      fileName = file.name
    else if (version === UPLOAD_FILE_TYPE.FILE_DEFINTION && fileDefinition != null && context?.changeFileName) {
      fileName = UploadFileService.computeFileName(file, fileDefinition, fileList)
    } else {
      fileName = UploadFileService.computeFileNameNoDuplicates(file, fileList)
    }

    const newFile = new File([fileBlob], fileName, {type: file.type})

    return uploadFile(newFile, {...directory, fileName}, updateFile)
      .then(async () => {
        return handleSuccessRequest(newFile).then((newFile) => {
          setLoading(false)
          return newFile
        })
      })
      .catch((err) => {
        handleErrorRequest(newFile).then(() => setLoading(false))
      })
  }

  async function handleRemove(file) {
    if (file == null) {
      return ERROR(ILLEGAL_ARGUMENT_EXCEPTION)
    }

    setLoading(true)
    onRemoveFile?.(directory.jsonPath, file)

    setFileList((prevFiles) => {
      return [...prevFiles].filter(f => f.name !== file.name)
    })
    setLoading(false)
  }

  async function handleDownloadFile(file) {
    if (file == null) {
      return ERROR(ILLEGAL_ARGUMENT_EXCEPTION)
    }

    const fileContent = await getFileListFromServer([file], null)
    const content = fileContent[file.name]

    if (content == null) {
      return ERROR('No content for this file ' + file.name)
    }

    const fileType = FileUploaderService.getFileType(file)
    downloadjs(content, file.name, fileType)
  }

  useEffect(() => {
    const { carouselRef, readOnly } = field

    if (carouselRef && fileList.length > 0) {
      carouselRef.current[field.id] = {
        fieldId: field.id,
        fieldName: __(field.title),
        fileList: fileList
          .filter((file) => isImage(file.type))
          .map((file) => ({
            ...file,
            imageId: `${field.id}${file.uid}`
          })),
        readOnly,
        downloadFile: handleDownloadFile,
        handleUploadFile,
        type: IMAGE_MODAL_PREVIEW_TYPE.FROM_DIRECTORY
      }
    }
    if (carouselRef && fileList.length === 0) {
      delete carouselRef.current[field.id]
    }
  }, [fileList]);

  const canAddFile = () => {
    return fileDefinition?.maxAmount == null || ((fileDefinition.files || []).length < fileDefinition.maxAmount)
  }

  return (
    <div className={`clearfix godoo-upload ${(!loading && !isReadOnly && !canAddFile()) ? "godoo-upload-disabled" : ""}`}>
      <Dragger
        listType="picture"
        fileList={fileList}
        onPreview={handlePreview}
        customRequest={handleUploadFile}
        onRemove={handleRemove}
        accept={accept}
        isImageUrl={renderIcon}
        disabled={isReadOnly}
        progress={{
          format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
          strokeWidth: 5,
        }}
        showUploadList={{showPreviewIcon: (field?.allowFilePreview || directory?.allowFilePreview) != null ? (field?.allowFilePreview || directory?.allowFilePreview) : true}}
      >
        {!loading && isReadOnly && fileList.length === 0 ?
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Files"} style={{marginTop: "2px"}}/> : null}
        {!loading && !isReadOnly && canAddFile() ?
          <Button
            className="addFiles"
            type="text"
            shape="round"
            icon={<PlusCircleTwoTone twoToneColor="#88bd4f"/>}
          >
            Add Files
          </Button> : null}
      </Dragger>
      {
        previewModalVisible && selectedImageName && (!field.setCarouselImageOpen) && (
          <ImageCarouselModal
            selectedImageName={selectedImageName}
            fileList={fileList}
            downloadFile={handleDownloadFile}
            handleUploadFile={handleUploadFile}
            directory={directory}
            previewVisible={previewModalVisible}
            onCloseModal={handleCloseModal}
            type={IMAGE_MODAL_PREVIEW_TYPE.FROM_DIRECTORY}
          />
        )
      }
    </div>
  )
}

// File Definition is required only if the specified version is selected
UploadFileComponent.propTypes = {
  authToken: PropTypes.string.isRequired,
  directory: PropTypes.shape({
    jsonPath: PropTypes.string.isRequired,
    acceptedMimeTypes: PropTypes.string
  }).isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    acceptedMimeTypes: PropTypes.string,
    readOnly: PropTypes.bool
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onUploadError: PropTypes.func,

  version: PropTypes.oneOf([UPLOAD_FILE_TYPE.DIRECTORY_DEFINITION, UPLOAD_FILE_TYPE.FILE_DEFINTION]),
  fileDefinition: function (props, propName, componentName) {
    if ((props['version'] === UPLOAD_FILE_TYPE.FILE_DEFINTION && (props[propName] == undefined || typeof (props[propName]) != 'object'))) {
      return new Error('Please provide a file definition for this component!');
    }
  },

  context: PropTypes.shape({
    changeFileName: PropTypes.bool
  })
}

const UploadFile = connect(
  state => {
    return {
      authToken: state.user.authToken
    }
  },
  {}
)(UploadFileComponent)

export default UploadFile
