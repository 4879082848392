import {api} from "./index";
import { ERROR } from "components/dist/Utils/LoggerUtils"

export const ping = (url: string) => api.get(`${url}/ping`)
  .then((response) => response?.data)

export type SavedRequest = {
  id: string
  method: "GET" | "POST"
  payload?: string
  requestParams?: object
  headers?: object
  uri: string
}

export const getRequest = async (reqId: string): Promise<SavedRequest | void> =>  {
  return api.get(`requests/${reqId}`)
    .then((res) => res.data as SavedRequest)
    .catch((err) => ERROR(err))
}
