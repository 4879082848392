import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getRequest, SavedRequest} from "../providers/ApiProvider";

type Cache = {
  id: string
  cacheId: string
}

type CacheAction = {
  type: string,
  payload: Cache
}

type CacheSelector = ((state: any) => { [id: string]: Cache })

const useCache = (objId: string, cacheSelector: CacheSelector) => {
  const dispatch = useDispatch()
  const cached: Cache | undefined = useSelector(cacheSelector)?.[objId]
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<void | SavedRequest>(undefined)

  useEffect(() => {
    if(!data) {
      if (!cached?.cacheId) {
        setLoading(false)
      }
      else {
        getRequest(String(cached.cacheId)).then((data) => {
          setData(data)
          setLoading(false)
        })
      }
    }
  }, [cached]);

  const saveCache: (cacheAction: CacheAction) => any = useCallback((cacheAction: CacheAction) => dispatch(cacheAction), [dispatch])

  return { loading, data, saveCache }
}

export default useCache
